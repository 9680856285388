<template>
    <span class="login-form__link forgot-pass__link" @click="handleForgotPassword">
        Forgot your password?
    </span>

    <CModal class="overflow-modal" :visible="modal" backdrop="static" @close="closeModal">
        <div class="app-modal login-form__modal">
            <div class="overflow-modal__close-bar">
                <CIcon name="cil-x" @click="closeModal"/>
            </div>

            <h4 class="forgot-pass__title">Forgot your password</h4>
            <p class="app-modal__text">
                Please enter your email and we'll send you instructions to reset your password
            </p>

            <CNInput v-model="formData.email" :invalid="!!validationErrors.email"
                     @blur="handleChangeField('email', $event)" maxlength="255" label="Enter your email"/>

            <CFormText v-if="validationErrors.email" :style="`color: ${validationErrors.email && 'red'}`">
                {{ validationErrors.email }}
            </CFormText>

            <div class="app-modal__buttons">
                <CButton style="min-width: 200px" variant="outline" color="primary" @click="closeModal">
                    Cancel
                </CButton>

                <CButton :disabled="submitLoading" style="min-width: 200px" color="primary" @click="handleSubmit">
                    <CSpinner v-if="submitLoading" size="sm"/>
                    <span v-else>Send instructions</span>
                </CButton>
            </div>
        </div>
    </CModal>
</template>

<script>
import validator from '@/utils/validator'
import rules from '@/utils/validator/rules'
import {parseError} from '@/utils/api'
import CNInput from "@/components/ui/CNInput/CNInput.vue";

export default {
    name: 'ForgotPasswordModal',
    components: {CNInput},
    inject: ['toast'],
    data() {
        return {
            modal: false,
            submitLoading: false,

            formData: {
                email: '',
            },

            validator: {},
            validationErrors: {}
        }
    },
    created() {
        this.setRules()
    },
    methods: {
        closeModal() {
            this.modal = false;
            this.formData.email = '';
            this.validationErrors = {};
        },

        handleForgotPassword() {
            this.modal = true;
        },
        handleSubmit(e) {
            const validationResult = this.validator.validateAll(this.formData);

            if (!validationResult.hasErrors) {
                this.submitLoading = true
                this.$http.auth
                    .forgotPassword({email: this.formData.email?.toLowerCase()})
                    .then(() => {
                        this.toast('info', 'If this email exists, we will send instructions to reset password')
                        this.closeModal();
                    })
                    .catch((err) => {
                        err = parseError(err)
                        if (err.status === 422) {
                            this.validationErrors = err.validationMessages
                        } else {
                            this.toast('warning', err.message)
                        }
                    })
                    .finally(() => {
                        this.submitLoading = false;
                    })
            } else {
                e.preventDefault();
                this.validationErrors = validationResult.validationErrors
            }
        },
        handleChangeField(field, event) {
            this.validationErrors[field] = this.validator.validate(
                field,
                event,
            )
        },
        setRules() {
            this.validator = validator({
                email: [rules.required, rules.email],
            })
        },
    },
}
</script>

<style scoped>
.forgot-pass__link {
    max-width: 160px;
}

.forgot-pass__title {
    font-size: 24px;
    color: #1C262F;
    text-align: center;
}
</style>
